.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.resultslist {
  display: flex;
  flex-direction: column;

}

.result {
  border: 1px solid black;
  justify-content: space-evenly;
  text-align: left;
  padding: 5px;
  margin: 0;
}

.result ul {
  list-style: none;
}